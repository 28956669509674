import { useToggle } from '@vueuse/core'

export function useVisible() {
  const [visible, toggle] = useToggle(false)

  function show() {
    return toggle(true)
  }

  function hide() {
    return toggle(false)
  }

  return {
    visible,
    show,
    hide,
    toggle,
  }
}
